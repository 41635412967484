.temp_puzzle_overlay {
  height: 100vh;
  width: 100vw;

  z-index: 10;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(5px);
  background-color: #000000b1;

  h1 {
    width: calc(30vh + 30vw);
    text-align: center;

    font-family: "DoctorSoosBold";

    color: rgb(255, 183, 0);

    //  text-shadow:
    // 4px 0 #f53f16,
    // -4px 0 #E14B2A,
    // 0 4px #E14B2A,
    // 0 -4px #E14B2A,
    // 2px 2px #E14B2A,
    // -2px -2px #E14B2A,
    // 2px -2px #E14B2A,
    // -2px 2px #E14B2A;

    font-size: calc(4vh + 4vw);
  }
}
