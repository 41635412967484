.spot_the_diff {
  font-family: "Architects Daughter", cursive;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/Spot_The_Difference_Base_Image.JPG");
  background-size: cover;
  background-repeat: no-repeat;
}

.spot_the_diff_image_modal {
  position: absolute;

  background-color: rgba(88, 88, 88, 0.473);
  backdrop-filter: blur(15px);

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  img {
    height: 100%;
    background-repeat: no-repeat;
  }
}

.spot_the_diff_image_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  height: 100vh;
  width: 40vw;

  position: absolute;
  top: 0;

  z-index: 2;
}

.spot_the_diff_image_modal_container_image_a {
  transform: translateX(-50%);
  left: 25vw;
}

.spot_the_diff_image_modal_container_image_b {
  transform: translateX(+50%);
  right: 25vw;
}

.spot_the_diff_image_modal_container_image {
  height: 100vh;
  width: 40vw;
}
