.code_input {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(18vh + 20vw);

  .code_input_container {
    .code_input_dark {
      color: #000;
    }

    input {
      height: calc(2vh + 2vw);
      width: calc(2vh + 2vw);

      text-align: center;

      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      border: 3px #352015 solid;

      outline: none;

      margin: 0 calc(0.2vh + 0.2vw);

      color: white;
      font-size: calc(1vh + 1vw);
      font-weight: 700;
      word-wrap: break-word;

      &:focus {
        background: rgba(255, 255, 255, 0.5);
        border: 3px goldenrod solid;
      }
    }
  }

  .code_input_submit {
    button {
      font-family: "Comic Sans MS", "Comic Sans", cursive;

      font-weight: 800;
      font-size: calc(0.8vh + 0.8vw);
      color: white;
      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;

      border-radius: 16px;
      display: flex;
      height: calc(2vh + 2vw);
      padding: calc(0.8vh + 0.8vw);
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border: 2px solid #41281f;
      background: #65bf45;
      box-shadow: 0px -8px 0px 0px #3c6b28 inset;

      &:active {
        background: #65bf45a7;

        transform: translateY(4px);
      }
    }
  }
}

.code_input_horizontal {
  flex-direction: row;
  height: calc(3vh + 3vw);

  .code_input_submit {
    button {
      width: calc(7vh + 7vw);
    }
  }
}

.code_input_vertical {
  flex-direction: column;
  height: calc(5vh + 5vw);

  .code_input_submit {
    button {
      width: calc(11vh + 11vw);
    }
  }
}
