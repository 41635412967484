.tree_puzzle_win_popup_container {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  z-index: 10;

  background-color: rgba(255, 255, 255, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.tree_puzzle_win_popup {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  color: white;

  width: calc(20vh + 20vw);
  padding: calc(2vh + 2vw) calc(1vh + 1vw);

  background-color: #4d3223;
  border-radius: 0.75rem;

  border: 8px solid #352015;
  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree-backgroud.png");

  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .tree_puzzle_win_popup_content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h1 {
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: calc(2vh + 2vw);

      text-align: center;

      margin: 0;

      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;
    }

    h2 {
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-size: calc(1vh + 1vw);

      text-align: center;

      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;
    }

    .progress_dots {
      height: calc(1vh + 1vw);
      display: flex;
      .progress_dot {
        height: calc(0.3vh + 0.3vw);
        width: calc(0.3vh + 0.3vw);
        border-radius: 50%;
        background-color: #ffffff00;
        margin: 0 0.5rem;
        border: 1px solid white;
      }

      .active {
        background-color: #21a60f;
      }
    }

    button {
      font-family: "Comic Sans MS", "Comic Sans", cursive;
      font-weight: 800;
      font-size: calc(0.8vh + 0.8vw);
      color: white;
      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;

      display: flex;
      width: calc(6vh + 10vw);
      padding: 0.8125rem 1.125rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      flex-shrink: 0;

      border-radius: 1rem;
      border: 2px solid #41281f;
      background: #108900;
      box-shadow: 0px -8px 0px 0px #21a60f inset;

      &:active {
        background: #eac50aa5;
        transform: translateY(4px);
      }
    }
  }
}
