.spot_the_diff_image_container {
  width: 35vw;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;

    display: block; /* helps in removing little bit of white space below the image */
  }
}
