.info_icon {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 8;
  cursor: pointer;

  img {
    width: calc(2vh + 2vw);
    height: calc(2vh + 2vw);
  }
}

.informationPopup__content {
  font-family: "DoctorSoosLight";

  position: absolute;
  top: 0;
  left: 0;
  // left: 50%;
  // transform: translateX(-50%);

  z-index: 9;

  height: 100vh;
  width: 100vw;

  // max-width: 1600px;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  .informationPopup__logo {
    margin-top: 3%;
    height: 27%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: calc(5vh + 5vw);
      width: auto;
    }
  }

  .informationpopup__text_content {
    height: 100%;
    width: 100%;

    display: flex;

    display: flex;
    justify-content: flex-start;

    .informationpopup__left,
    .informationpopup__right {
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .informationpopup__title {
        width: 100%;
        text-align: left;
        font-size: calc(0.6vh + 0.6vw);
        text-shadow:
          2px 0 #fff,
          -2px 0 #fff,
          0 2px #fff,
          0 -2px #fff,
          1px 1px #fff,
          -1px -1px #fff,
          1px -1px #fff,
          -1px 1px #fff;

        h1 {
          margin: calc(0.1vh + 0.1vw) 0;
        }
      }
    }

    .informationpopup__left {
      padding: 0% 1% 0% 5%;
      .informationpopup__left__title {
        color: #13a21c;
      }
      .informationpopup_line_items {
        .line_item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          p {
            margin-top: 0;
            margin-bottom: calc(0.3vh + 0.3vw);
            font-size: calc(1vh + 1vw);
          }

          img {
            height: calc(1vh + 1vw);
            width: auto;
            margin: 0 calc(1vh + 1vw) 0 0;
          }
        }
      }
    }

    .informationpopup__right {
      padding: 0% 5% 0% 1%;
      .informationpopup__right__title {
        color: #d43313;
      }
      .informationpopup_line_items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .informationpopup_line_item {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          label {
            display: flex;
            justify-content: flex-start;

            font-size: calc(1vh + 1vw);

            margin: calc(0.3vh + 0.3vw) 0;
          }
        }
      }

      .informationpopup_video {
        position: relative;
        height: calc(27vh);
        width: calc(48vh);

        border-radius: 5px;
        border: 5px solid black;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: calc(0.5vh + 0.5vw) 0;

        .video_container_minimized {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          margin: 0;
          padding: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          video {
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
        }

        .video_container_fullscreen {
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;

          margin: 0;
          padding: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          overflow: hidden;

          video {
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
}

.control_button_small {
  bottom: 5%;
  font-family: "DoctorSoosBold";
  cursor: pointer;

  font-weight: 800;
  font-size: calc(0.3vh + 0.3vw);
  color: white;

  border-radius: 10px;
  display: flex;
  width: calc(3vh + 3vw);
  padding: calc(0.1vh + 0.1vw) calc(0.1vh + 0.2vw);
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid #ffffff;
  background: #65bf45;
  box-shadow: 0px -2px 0px 0px #3c6b28 inset;

  &:active {
    background: #65bf45a7;
    transform: translateY(4px);
  }

  img {
    height: calc(0.5vh + 0.5vw);
    width: auto;
  }
}

.control_button_large {
  bottom: -50%;
  font-family: "DoctorSoosBold";
  cursor: pointer;

  font-weight: 800;
  font-size: calc(1.2vh + 1.2vw);
  color: white;
  border-radius: 12px;
  display: flex;
  width: calc(6vh + 6vw);
  padding: calc(0.3vh + 0.3vw);
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 2px solid #ffffff;
  background: #65bf45;
  box-shadow: 0px -8px 0px 0px #3c6b28 inset;

  &:active {
    background: #65bf45a7;

    transform: translateY(4px);
  }

  img {
    height: calc(1.5vh + 1.5vw);
    width: auto;
  }
}

.control_button_center {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(2.5vh + 2.5vw);
  width: calc(2.5vh + 2.5vw);
  border-radius: 100%;

  img {
    height: calc(1.5vh + 1.5vw);
    width: auto;
  }
}

.control_button_center_small {
  top: 30%;
}

.control_button_center_large {
  top: -30%;
}

.button_red {
  background: #e14b2a;
  box-shadow: 0px -6px 0px 0px #e14b2a inset;

  &:active {
    background: #c8482c;
    transform: translateY(4px);
  }
}

.button_yellow {
  background: #eac60a;
  box-shadow: 0px -6px 0px 0px #bea93a inset;

  &:active {
    background: #bea93a;
    transform: translateY(4px);
  }
}
