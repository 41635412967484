.cancel_button {
  position: absolute;
  cursor: pointer;

  img {
    height: calc(2vh + 2vw);
    width: calc(2vh + 2vw);
  }

  &:hover {
    img {
      height: calc(2.1vh + 2.1vw);
      width: calc(2.1vh + 2.1vw);
    }
  }
}
