body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DoctorSoosLight";
  src:
    local("DoctorSoosLight"),
    url("./Assets/fonts/Doctor\ Soos\ Light\ 1.1.ttf") format("truetype");
}

@font-face {
  font-family: "DoctorSoosBold";
  src:
    local("DoctorSoosBold"),
    url("./Assets/fonts/Doctor\ Soos\ Bold\ 2.1.ttf") format("truetype");
}
