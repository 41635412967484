.crazy-num-logic {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
}

.crazy-num-logic_screen {
  position: relative;

  font-family: "Comic Sans MS", "Comic Sans", cursive;

  background-color: rgba(0, 0, 0, 0.452);
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .crazy-num-logic_start_button {
    font-family: "Comic Sans MS", "Comic Sans", cursive;

    font-weight: 800;
    font-size: calc(0.8vh + 0.8vw);
    color: white;
    text-shadow:
      2px 0 #000,
      -2px 0 #000,
      0 2px #000,
      0 -2px #000,
      1px 1px #000,
      -1px -1px #000,
      1px -1px #000,
      -1px 1px #000;

    border-radius: 16px;
    display: flex;
    width: calc(7vh + 7vw);
    padding: calc(0.8vh + 0.8vw);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border: 2px solid #41281f;
    background: #65bf45;
    box-shadow: 0px -8px 0px 0px #3c6b28 inset;

    &:active {
      background: #65bf45a7;

      transform: translateY(4px);
    }
  }

  .crazy-num-logic_try_again_button {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-weight: 800;
    font-size: calc(0.8vh + 0.8vw);
    color: white;
    text-shadow:
      2px 0 #000,
      -2px 0 #000,
      0 2px #000,
      0 -2px #000,
      1px 1px #000,
      -1px -1px #000,
      1px -1px #000,
      -1px 1px #000;

    display: flex;
    padding: 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1rem;
    border: 2px solid #41281f;
    background: #eac60a;
    box-shadow: 0px -8px 0px 0px #a68d0f inset;

    &:active {
      background: #eac50aa5;

      transform: translateY(4px);
    }
  }

  .crazy-num-logic_input {
    font-family: "Comic Sans MS", "Comic Sans", cursive;

    display: flex;
    width: calc(8vh + 28vw);
    height: calc(5vh + 1vw);
    align-items: center;
    flex-shrink: 0;

    color: #11eb09;

    border-radius: calc(0.5vh + 0.5vw);
    border: 2px solid #11eb09;
    background: #000;

    label {
      font-family: "Comic Sans MS", "Comic Sans", cursive;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: calc(1.2vh + 1.2vw);

      height: 100%;
      width: 10%;
      border-right: 2px solid #11eb09;
    }

    input {
      font-family: "Comic Sans MS", "Comic Sans", cursive;

      width: 85%;
      height: 90%;

      border: none;

      color: #11eb09;

      font-size: calc(1.2vh + 1.2vw);

      outline: none;

      background: black;
    }
  }
}

.crazy-num-logic_complete_popup_container {
  position: absolute;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .crazy-num-logic_complete_popup {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.75rem;
    border: 8px solid #4e4e4e;

    font-family: "Roboto Mono", monospace;

    font-size: calc(1.5vh + 1.5vw);

    padding: calc(1vh + 1vw);

    text-align: center;

    color: #11eb09;

    width: calc(25vh + 25vw);

    background-color: #000000;
    border-radius: 0.75rem;
  }
}

.crazy-num-logic_welcome_screen {
  height: 100%;
  width: 100%;

  font-family: "Roboto Mono", monospace;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  .crazy-num-logic_welcome_screen_content {
    height: 40%;
    width: 60%;

    h1 {
      color: #11eb09;
      text-align: center;
    }
  }
}

.animate_instant_fade_out {
  animation: fade-out 0.1s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

.animate_fade_in {
  animation: fade-in 3s;
}

.animate_fade_in_2 {
  animation: fade-in-2 3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-in-2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
