.search_word_grid_paper {
  height: 110%;
  width: calc(25vh + 40vw);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Search-word/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  h1 {
    text-transform: uppercase;

    text-align: center;

    width: calc(20vh + 20vw);

    width: 80%;

    margin: 0;

    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-weight: 800;
    font-size: calc(2vh + 0.5vw);
    color: white;
    text-shadow:
      2px 0 #000,
      -2px 0 #000,
      0 2px #000,
      0 -2px #000,
      1px 1px #000,
      -1px -1px #000,
      1px -1px #000,
      -1px 1px #000;
  }
}

.search_word_grid_paper_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  margin-top: calc(3vh + 3vw);
  margin-bottom: calc(0.1vh + 0.1vw);

  button {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-weight: 800;
    font-size: calc(0.8vh + 0.8vw);
    color: white;
    text-shadow:
      2px 0 #000,
      -2px 0 #000,
      0 2px #000,
      0 -2px #000,
      1px 1px #000,
      -1px -1px #000,
      1px -1px #000,
      -1px 1px #000;

    display: flex;
    width: calc(7vh + 7vw);
    padding: calc(0.2vh + 0.2vw);
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1rem;

    &:active {
      opacity: 0.8;
      transform: translateY(4px);
    }
  }
}

.search_word_grid_paper_erase {
  border: 2px solid #41281f;
}

.search_word_grid_paper_clear_all {
  border: 2px solid #41281f;
  background: transparent;

  &:active {
    border: 2px solid #41281f;
    background: #ffe500;
    box-shadow: 0px -8px 0px 0px #d3bd00 inset;
  }
}

.search_word_grid_paper_clear_popup_container {
  position: absolute;

  top: 0;
  left: 0;
  z-index: 1;

  background-color: #00000068;

  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  .search_word_grid_paper_clear_popup {
    z-index: 5;

    height: calc(10vh + 10vw);
    width: calc(20vh + 20vw);

    border-radius: calc(1vh + 1vw);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #41281f;
  }

  .search_word_grid_paper_clear_popup_buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    margin: calc(1vh + 1vw);

    button {
      font-family: "Comic Sans MS", "Comic Sans", cursive;
      font-weight: 800;
      font-size: calc(0.6vh + 0.6vw);
      color: white;
      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;

      display: flex;
      width: calc(7vh + 7vw);
      padding: calc(0.5vh + 0.5vw);
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      border-radius: 1rem;

      &:active {
        opacity: 0.8;
        transform: translateY(4px);
      }
    }
  }

  .search_word_grid_paper_clear_popup_yes {
    border: 2px solid #41281f;
    background: #65bf45;
    /* Button Shadow */
    box-shadow: 0px -8px 0px 0px #3c6b28 inset;
  }

  .search_word_grid_paper_clear_popup_no {
    border: 2px solid #41281f;
    background: #e14b2a;
    box-shadow: 0px -8px 0px 0px #b12a0c inset;
  }
}
