.reveal_box {
  font-family: "Roboto Mono", monospace;

  display: flex;
  height: calc(8vh + 3vw);
  width: calc(4vh + 14vw);
  font-size: calc(0.5vh + 0.5vw);

  color: palegreen;

  border-radius: 0.3125rem;

  font-weight: 500;

  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.25);
    opacity: 0.9;
  }

  .reveal_box__inner_left {
    font-family: "Roboto Mono", monospace;

    flex: 0.1;
    background-color: black;
    border: 2px solid #000;
    padding: calc(0.5vh + 0.5vw);

    border-radius: 0.3125rem 0 0 0.3125rem;
  }

  .reveal_box__inner_right {
    font-family: "Roboto Mono", monospace;

    font-size: calc(0.5vh + 0.5vw);

    display: flex;
    justify-content: center;
    align-items: center;

    flex: 0.9;
    background-color: #1c1918;
    border: 2px solid #000;
    padding: calc(0.5vh + 0.5vw);

    border-radius: 0 0.3125rem 0.3125rem 0;

    .reveal_box__inner_right__text {
      font-size: calc(1vh + 1vw);
      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;
    }
  }
}

.reveal_box_selected {
  color: white;

  .reveal_box__inner_left {
    background-color: #09a403;
    font-weight: 800;
  }

  .reveal_box__inner_right {
    background-color: #10de09;
    font-weight: 800;
  }
}

.reveal_box_not_selected {
  color: rgba(17, 235, 9, 1);

  .reveal_box__inner_left {
    background-color: black;
  }

  .reveal_box__inner_right {
    background-color: #1c1918;
  }
}

.reveal_box_wrong_selected {
  color: white;

  .reveal_box__inner_left {
    background-color: #c8482c;
    font-weight: 800;
  }

  .reveal_box__inner_right {
    background-color: #e14b2a;
    font-weight: 800;
  }
}

.reveal_box_wrong_not_selected {
  color: white;

  .reveal_box__inner_left {
    background-color: black;
  }

  .reveal_box__inner_right {
    background-color: #6c6c6c;
  }
}
