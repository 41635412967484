.search_word_letter_grid_container {
  position: relative;
  overflow: hidden;
}

.search_word_letter_grid {
  display: grid;
  grid-template-columns: repeat(27, 1fr);

  width: calc(36vh + 23vw);

  padding: calc(0.5vh + 0.5vw);
}

.search_word_item {
  font-family: "Comic Sans MS", "Comic Sans", cursive;

  height: 100%;
  width: 100%;

  border: 0.5px solid #d4d4d4;

  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: calc(0.95vh + 0.3vw);

  &:hover {
    background-color: #e6e6e6;
  }
}

// for vertical and horizontal lines
.search_word_letter_grid_cursor {
  z-index: 1;
  pointer-events: none;
}

.search_word_letter_grid_vt {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: red;
}

.search_word_letter_grid_hl {
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  background: red;
}
