.rating_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  background: #5c4332;
  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree-backgroud.png");

  .logo {
    margin: calc(1vh + 1vw) 0;
    img {
      height: calc(5vh + 5vw);
    }
  }

  .rating_screen_content {
    font-family: "Comic Sans MS", "Comic Sans", cursive;

    width: calc(20vh + 20vw);

    background: rgba(0, 0, 0, 0.5);

    border-radius: calc(1vh + 1vw);
    padding: calc(1.5vh + 1.5vw);

    .rating_screen_star_rating {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .rating_screen_star_rating_title {
        width: 100%;
        text-align: left;
        color: white;
        margin: calc(0.3vh + 0.3vw) 0;
        font-size: calc(0.8vh + 0.8vw);
        font-weight: 800;
      }

      .rating_screen_star_rating_stars {
        width: calc(15vh + 5vw);
        margin: calc(0.3vh + 0.3vw) auto calc(0.5vh + 0.5vw) 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rating_screen_star_rating_star {
          cursor: pointer;

          transition: 0.2s ease-in-out all;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .rating_screen_comments {
      width: 100%;

      .rating_screen_comments_label {
        width: 100%;
        text-align: left;
        color: white;
        margin: calc(0.3vh + 0.3vw) 0;
        font-size: calc(0.8vh + 0.8vw);
        font-weight: 800;
      }

      .rating_screen_comments_textarea {
        width: 100%;

        textarea {
          width: 94%;
          height: calc(6vh + 6vw);

          font-family: "Comic Sans MS", "Comic Sans", cursive;

          padding: calc(0.5vh + 0.5vw);

          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          border: 3px #352015 solid;

          outline: none;
          resize: none;

          color: white;
          font-size: calc(0.7vh + 0.7vw);
          font-weight: 700;
          word-wrap: break-word;

          &:focus {
            background: rgba(255, 255, 255, 0.2);
          }
        }

        ::placeholder {
          color: white;
          font-weight: 300;
          font-size: calc(0.7vh + 0.7vw);
        }
      }
    }

    .rating_screen_submit {
      width: 100%;
      button {
        font-family: "Comic Sans MS", "Comic Sans", cursive;

        font-weight: 800;
        font-size: calc(0.8vh + 0.8vw);
        color: white;
        text-shadow:
          2px 0 #000,
          -2px 0 #000,
          0 2px #000,
          0 -2px #000,
          1px 1px #000,
          -1px -1px #000,
          1px -1px #000,
          -1px 1px #000;

        margin: calc(1.5vh + 1.5vw) 0 0 0;

        border-radius: 16px;
        display: flex;
        width: 100%;
        padding: calc(0.8vh + 0.8vw);
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border: 2px solid #41281f;
        background: #65bf45;
        box-shadow: 0px -8px 0px 0px #3c6b28 inset;

        &:active {
          background: #65bf45a7;

          transform: translateY(4px);
        }
      }
    }

    .rating_screen_submited {
      width: 100%;
      button {
        font-family: "Comic Sans MS", "Comic Sans", cursive;

        font-weight: 800;
        font-size: calc(0.8vh + 0.8vw);
        color: white;
        text-shadow:
          2px 0 #000,
          -2px 0 #000,
          0 2px #000,
          0 -2px #000,
          1px 1px #000,
          -1px -1px #000,
          1px -1px #000,
          -1px 1px #000;

        margin: calc(1.5vh + 1.5vw) 0 0 0;

        border-radius: 16px;
        display: flex;
        width: 100%;
        padding: calc(0.8vh + 0.8vw);
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border: 2px solid #41281f;
        background: #e14b2a;
        box-shadow: 0px -8px 0px 0px #c8482c inset;

        &:active {
          background: #c8482c;

          transform: translateY(4px);
        }
      }
    }
  }
}
