.input_grid {
  width: 40%;
  height: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  flex-direction: column;
}

.input_grid_container {
  display: grid;
  grid-template-columns: repeat(13, 0fr);
  grid-gap: 1px;
}

.input_grid_container_input {
  text-align: center;

  font-family: "Architects Daughter", cursive;

  font-size: calc(0.8vh + 0.8vw);

  color: white;

  border: none;
  outline: none;

  background-color: transparent;

  width: calc(1vh + 1vw);
  height: calc(1.4vh + 1.4vw);

  margin: calc(0.1vh + 0.1vw);
}

.input_grid_container_input_border_highlighted {
  color: red;

  border: none;
  border-bottom: red solid 2px;
}

.input_grid_container_input_border {
  border: none;
  border-bottom: white solid 2px;
}

.input_grid_wrong_answer_timer {
  color: #eed70a;
  font-size: calc(0.7vh + 0.7vw);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 3px;
}

.input_grid_submit_answer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    font-family: "Architects Daughter", cursive;

    background: transparent;
    color: white;

    outline: none;

    width: calc(5vh + 24vw);

    font-size: calc(0.8vh + 0.8vw);

    text-align: center;

    border: 20px solid transparent;
    border-image-source: url("https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/input_border.png");
    border-image-slice: 30; /* Define how the image should be sliced */
    border-image-repeat: round;
  }

  input::placeholder {
    color: white; /* Change the text color for this input */
  }

  input:focus::placeholder {
    color: transparent;
  }

  .input_grid_submit_answer_btn {
    font-family: "Architects Daughter", cursive;
    margin-top: calc(0.5vh + 0.5vw);

    color: white;
    font-weight: 800;
    font-size: calc(0.8vh + 0.8vw);

    display: flex;
    width: calc(5vh + 5vw);
    padding: calc(0.8vh + 0.8vw);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    user-select: none;

    border-radius: 1rem;
    border: 2px solid #41281f;
    background: #65bf45;
    /* Button Shadow */
    box-shadow: 0px -8px 0px 0px #3c6b28 inset;

    &:active {
      transform: translateY(2px);
      opacity: 0.8;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
