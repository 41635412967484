.diary {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.diary_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// override module css
.transform-component-module_content__FBWxo {
  height: 90vh !important;
}
