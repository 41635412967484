.tree_puzzle_grid_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  // margin-top: calc(5vh + 5vw);
}

.tree_puzzle_container {
  transform: translateY(3%);
  display: grid;
  gap: 0;
}

.tree_puzzle_container_cell {
  height: calc(2.2vh + 2.2vw);
  width: calc(2.2vh + 2.2vw);

  // add media query for height less than 700px
  @media (max-height: 800px) {
    height: calc(1.9vh + 1.9vw);
    width: calc(1.9vh + 1.9vw);
  }

  // if width is more than 2000px and height less than 1200px
  @media (min-width: 2000px) and (max-height: 1200px) {
    height: calc(1.9vh + 1.9vw);
    width: calc(1.9vh + 1.9vw);
  }

  user-select: none;

  &:hover {
    opacity: 0.7;
  }

  .tree_puzzle__container_row_item_content {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: calc(1.4vh + 1.4vw);
      width: calc(1.4vh + 1.4vw);
    }

    p {
      font-family: "Comic Sans MS", "Comic Sans", cursive;
      font-weight: 800;
      font-size: calc(0.6vh + 0.6vw);
      color: white;
      text-shadow:
        2px 0 #000,
        -2px 0 #000,
        0 2px #000,
        0 -2px #000,
        1px 1px #000,
        -1px -1px #000,
        1px -1px #000,
        -1px 1px #000;
    }
  }
}
