.wardrobe_lock {
  position: relative;

  font-family: "DoctorSoosLight";

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wardrobe_lock_answer {
    position: absolute;
    top: 42%;
    left: 38%;

    transform: translate(-50%, -50%);

    input {
      font-family: "DoctorSoosLight";
      text-align: center;
      background-color: transparent;
      outline: none;
      border: none;
      width: calc(10vh + 15vw);
      font-size: calc(1.8vh + 1.8vw);
      pointer-events: none;
    }

    input::placeholder {
      font-size: calc(1vh + 1vw);
    }
  }

  .button_grid_container {
    position: absolute;

    top: 52%;
    left: 26%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button_row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .button {
        height: calc(4.5vh);
        width: calc(3.5vw);
        background-color: #e2c084;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;

        cursor: pointer;

        user-select: none;

        box-shadow: 5px 5px 5px #000000;

        margin: calc(0.1vh + 0.1vw);

        font-size: calc(1.5vh + 1.5vw);

        &:active {
          transform: translateY(1px) scale(0.98);
          box-shadow: 2px 2px 5px #000000;
        }

        &:hover {
          background-color: #e7bc73;
        }
      }
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
