.tree_puzzle {
  display: flex;
  justify-content: space-around;

  height: 100%;
  width: 100%;

  overflow: hidden;

  background: #5c4332;
  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree-backgroud.png");

  .tree_puzzle_content {
    padding-top: calc(2vh + 2vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tree_puzzle_reset {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: 800;
  font-size: calc(0.8vh + 0.8vw);
  color: white;
  text-shadow:
    2px 0 #000,
    -2px 0 #000,
    0 2px #000,
    0 -2px #000,
    1px 1px #000,
    -1px -1px #000,
    1px -1px #000,
    -1px 1px #000;
  position: absolute;
  top: 8vh;

  display: flex;
  width: calc(10vh + 16vw);
  padding: calc(0.4vh + 0.4vw) calc(0.3vh + 0.3vw);
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;

  border-radius: 1rem;
  border: 2px solid #41281f;
  background: #eac60a;
  box-shadow: 0px -8px 0px 0px #a68d0f inset;

  &:active {
    background: #eac50aa5;
    transform: translateY(4px);
  }
}

.treepuzzle_codeInput {
  position: absolute;
  top: 6vh;
}
