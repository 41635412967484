.crazy-num-logic_how_to_play {
  font-family: "Roboto Mono", monospace;

  font-size: calc(0.8vh + 0.8vw);

  position: absolute;
  top: calc(2vh + 1.5vw);
  left: calc(3vh + 3vw);

  transform: translateY(-50%);

  cursor: pointer;

  display: flex;

  color: white;

  h3 {
    margin: calc(0.5vh + 0.5vw) 0;
  }

  img {
    margin: 0 calc(0.5vh + 0.5vw);
  }

  &:hover {
    transform: scale(1.1) translateY(-50%);
    transition: 0.1s ease-in-out all;
  }
}

.crazy-num-logic_how_to_play__help__container {
  font-family: "Roboto Mono", monospace;

  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.319);
}

.crazy-num-logic_how_to_play__help {
  top: calc(3.5vh + 3.5vw);
  left: calc(5vh + 5vw);

  width: calc(22vh + 22vw);

  color: #10de09;

  background-color: black;
  position: absolute;

  display: flex;
  padding: 0.75rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 0.75rem;
  border: 2px solid #4e4e4e;
  background: #000;
  box-shadow: 10px 10px 8px 0px rgba(0, 0, 0, 0.29);
}
