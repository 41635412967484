.video_screen {
  height: 100vh;
  width: 100vw;

  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  video {
    height: 100vh;
    width: 100vw;
    object-fit: fill;
  }
}

.videoScreenButton {
  font-family: "DoctorSoosBold";

  cursor: pointer;

  user-select: none;

  font-weight: 800;
  font-size: calc(1.2vh + 1.2vw);
  color: white;
  border-radius: 16px;
  display: flex;
  padding: calc(0.2vh + 0.2vw) calc(0.2vh + 0.6vw);
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 2px solid #ffffff;
  background: #65bf45;
  box-shadow: 0px -6px 0px 0px #3c6b28 inset;

  &:active {
    background: #65bf45a7;
    transform: translateY(4px);
  }

  img {
    height: calc(1.2vh + 1.2vw);
    width: calc(1.2vh + 1.2vw);
  }
}

.videoScreenButton_Red {
  background: #e14b2a;
  box-shadow: 0px -6px 0px 0px #e14b2a inset;

  &:active {
    background: #c8482c;
    transform: translateY(4px);
  }
}

.videoScreenButton_Yellow {
  background: #eac60a;
  box-shadow: 0px -6px 0px 0px #bea93a inset;

  &:active {
    background: #bea93a;
    transform: translateY(4px);
  }
}

.videoScreenButton_round {
  padding: calc(0.4vh + 0.4vw);

  height: calc(2vh + 2vw);
  width: calc(2vh + 2vw);
  border-radius: 100%;
}
