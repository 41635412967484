.tree-balance_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.tree-balance {
  // margin-top: calc(2vh + 2vw);
  margin-right: calc(2.5vh + 2.5vw);

  height: calc(22vh + 22vw);
  width: calc(7vh + 7vw);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

  padding: 1.5rem;

  border-radius: 1rem;
  border: 12px solid #352015;
  background: rgba(0, 0, 0, 0.3);

  border: 2px solid hotpink;

  img {
    height: calc(2.25vh + 2.25vw);
    pointer-events: none;
    user-select: none;
  }
}
