.quick_solve {
  font-size: calc(0.8vh + 0.8vw);

  position: absolute;
  right: calc(3vh + 3vw);

  transform: translateY(-15%);
}

.quick_solve_content {
  position: absolute;

  top: calc(3vh + 3vw);
  right: calc(-2vh + -2vw);

  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(14vh + 14vw);

  background-color: yellow;

  padding-bottom: calc(1vh + 1vw);

  border-radius: calc(0.5vh + 0.5vw);

  h5 {
    width: calc(10vh + 10vw);
    font-family: "Roboto Mono", monospace;
  }
}

.quick_solve_button {
  height: calc(2.2vh + 2.2vw);
  width: calc(2.2vh + 2.2vw);

  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #ffe500;
  box-shadow: 0px -6px 0px 0px #bdac155a inset;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &:active {
    background: #ffe500a7;
    transform: translateY(4px);
  }

  img {
    height: calc(1.5vh + 1.5vw);
    width: calc(1.5vh + 1.5vw);
  }
}
