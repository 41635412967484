.reveal_box_grid {
  border-radius: calc(0.8vh + 0.8vw);
  background-color: rgba(255, 255, 255, 0.2);
  padding: calc(0.75vh + 0.5vw);
}
.reveal_box_grid_container {
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  grid-gap: calc(0.4vh + 0.4vw);
}
