.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 2.2em; /* Adjust as needed */
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2em; /* Adjust as needed */
  width: 1.2em; /* Adjust as needed */
  background-color: #aa000000;
  border: 0.125em solid black; /* Adjust as needed */
  border-radius: 0.3em; /* Adjust as needed */
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: rgba(177, 33, 43, 1);
  border: 0.125em solid rgba(177, 33, 43, 1);
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
  left: 0.425em; /* Adjust as needed */
  top: 0.225em; /* Adjust as needed */
  width: 0.1875em; /* Adjust as needed */
  height: 0.625em; /* Adjust as needed */
  border: solid rgb(255, 255, 255);
  border-width: 0 0.125em 0.125em 0;
  transform: rotate(45deg);
}
