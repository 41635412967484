.jigsaw {
  height: 100%;
  width: 100%;

  background-image: url("https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree-backgroud.png");
  background-size: 100% 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: #735545;

  box-sizing: border-box;
  border: 12px solid #4a3226;

  border-radius: calc(1vh + 1vw);
}
